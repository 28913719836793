'use strict';

angular.module('enervexSalesappApp').controller('BomRulesCtrl', function($scope, BomRule, _, FileUploader, Flash) {
	$scope.allBomRules = [];
	$scope.offset = 0;
	$scope.limit = 5000;
	$scope.flag = "";
	function fetch() {
		BomRule.query({
			offset:$scope.offset,
			limit: $scope.limit
		}).$promise.then(function(bomRules){
			if (bomRules.length > 0) {
				$scope.allBomRules = $scope.allBomRules.concat(bomRules);
				$scope.offset = $scope.offset + $scope.limit;
				fetch();
			} else {
				$scope.applyFilter()
				// $scope.bomRules = _.sortBy($scope.allBomRules, function(bomRule){
				// 	return bomRule.category
				// })
			}
		});
	}
	function refetch() {
		$scope.allBomRules = []
		$scope.offset = 0;
		fetch()
	}
	fetch();
	$scope.categories = [
		"exhaust-adapter", 
		"exhaust-baffle", 
		"exhaust-control", 
		"exhaust-controls",
		"exhaust-damper", 
		"exhaust-fan", 
		"exhaust-mprbox", 
		"exhaust-prt2000", 
		"exhaust-relay-box", 
		"exhaust-relay-module", 
		"new-adapter", 
		"plenum-boxes", 
		"supply-adapter",
		"supply-control", 
		"supply-controls", 
		"supply-fan", 
		"supply-pressure-transducer", 
		"supply-prt2000", 
		"supply-single-phase",
		"supply-static-pressure-sensor",
		"system-wide",
		"final-category"
	];
	$scope.delete = function(bomRule) {
		BomRule.delete({
			id: bomRule._id
		});
		$scope.allBomRules = _.filter($scope.allBomRules, function(r){
			return r._id != bomRule._id
		})
		$scope.applyFilter()
	}
	$scope.export = function() {
		var params = [];
		// if ($scope.upload.application && $scope.upload.application._id){
		// 	params.push("application="+$scope.upload.application._id)
		// }
		// if ($scope.upload.applianceCompany && $scope.upload.applianceCompany._id){
		// 	params.push("applianceCompany="+$scope.upload.applianceCompany._id)
		// }
		// if ($scope.upload.applianceModel && $scope.upload.applianceModel.model){
		// 	params.push("model="+$scope.upload.applianceModel.model)
		// }
		// if ($scope.upload.isDuplicate && $scope.upload.isDuplicate != ""){
		// 	params.push("isDuplicate="+$scope.upload.isDuplicate)
		// }
		var url = "/api/bom-rules/export";
		// if (params.length >0) {
		// 	url = url + "?" + params.join("&")
		// }
		window.location.href = url;
	}
	$scope.applyFilter = function() {
		var nameReg = ($scope.term && $scope.term != '') ? RegExp($scope.term, "i") : null;
		$scope.bomRules = _.filter($scope.allBomRules, function(rule){
			if (rule.conditionType != "javascript"){
				if (!rule.logicsText){
					rule.logicsText = rule.logics.join("\n")
				}
				if (!rule.flagsText && rule.flags){
					rule.flagsText = rule.flags.join("\n")
				}
			}
			if (nameReg && !(nameReg.test(rule.action) || nameReg.test(rule.logicsText) || nameReg.test(rule.flagsText) || nameReg.test(rule.filter) || (rule.product && nameReg.test(rule.product.name)) || nameReg.test(rule.legacyId) )) {
				return false;
			} else if ($scope.category && $scope.category != '' && $scope.category != rule.category.name) {
				return false;
			} else if ($scope.flag == 'not-flagged' && rule.flags && rule.flags.length > 0) {
				return false;
			} else if ($scope.flag == 'flagged' && (!rule.flags || rule.flags.length == 0)) {
				return false;
			} else {
				return true
			}
		})
	}
	$scope.uploader = new FileUploader({});
	$scope.import = function() {
		_.each($scope.uploader.queue, function(data){
			var fd = new FormData();
			fd.append('name', data._file.name);
			fd.append('asset', data._file);
			$scope.uploadResponse = false;
			Flash.clear()
			BomRule.import(fd).$promise.then(function(res){
				
				var message = "Successfully uploaded " + res.count +" bomrules(s) in " + res.diff + " millis";

				Flash.create('info', '<strong>Success!</strong> ' + message, 0, {}, true);
				// $scope.uploadResponse = res
				$scope.uploader.clearQueue()
				refetch()
			}).catch(function(e){
				
				var message = "";
				if (e.data ) {
					message = message + e.data.message
				} else {
					message = message + e
				}
				Flash.create('danger', '<strong>Failure!</strong> ' + message, 0, {}, true);
				$scope.uploader.clearQueue()
			})
		});
	}
});
